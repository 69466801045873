import moment from 'moment';

export const dates = [
  { value: 'today', label: 'Today', range: () => [moment().startOf('day'), moment().endOf('day')] },
  { value: 'thisWeek', label: 'This Week', range: () => [moment().startOf('week'), moment().endOf('week')] },
  { value: 'thisMonth', label: 'This Month', range: () => [moment().startOf('month'), moment().endOf('month')] },
  { value: 'thisQuarter', label: 'This Quarter', range: () => [moment().startOf('quarter'), moment().endOf('quarter')] },
  { value: 'thisYear', label: 'This Year', range: () => [moment().startOf('year'), moment().endOf('year')] },
  {
    value: 'yesterday',
    label: 'Yesterday',
    range: () => [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')]
  },
  {
    value: 'previousWeek',
    label: 'Previous Week',
    range: () => [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')]
  },
  {
    value: 'previousMonth',
    label: 'Previous Month',
    range: () => [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  },
  {
    value: 'previousQuarter',
    label: 'Previous Quarter',
    range: () => [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')]
  },
  {
    value: 'previousYear',
    label: 'Previous Year',
    range: () => [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  }
];

export const columns = [
  {
    Header: 'Date Range',
    accessor: 'dateRange'
  },
  {
    Header: 'Products',
    accessor: 'product'
  },
  {
    Header: 'Total Users',
    accessor: 'totaluser'
  },
  {
    Header: 'Active Users ',
    accessor: 'active'
  },
  {
    Header: 'InActive Users',
    accessor: 'inactive'
  }
];

export const dailyacitivitycolumns = [
  {
    Header: 'Date',
    accessor: ({ date }: any) => date
  },
  {
    Header: 'Existing Subscription',
    accessor: ({ existing }: any) => existing
  },
  {
    Header: 'New Subscription',
    accessor: ({ active }: any) => active?.length
  },
  {
    Header: 'Expired Subscription',
    accessor: ({ expired }: any) => expired?.length
  },
  {
    Header: 'Total Active Subscription',
    accessor: ({ total }: any) => total
  }
];
