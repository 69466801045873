import React, { useState } from 'react';
import { Stack, TextField, Button } from '@mui/material';
import moment from 'moment';
import axios from 'utils/axios';
import { BASE_URL } from 'config';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
interface DateRangePickerchartProps {
  startDate: Date | null;
  endDate: Date | null;
  subscriptionreport:boolean;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setsubscriptionreport: React.Dispatch<React.SetStateAction<boolean>>;
}
// type loading = React.Dispatch<React.SetStateAction<DateRangePickerProps>>;

const bgColor = {
  background: 'linear-gradient(103.4deg, #2D00D2 12.04%, #2C00D3 30.87%, #3700C8 46.11%, #3E00C0 63.54%, #4B00B4 80.82%, #5400AA 93.76%)',
  color: ' #FFFFFF',
  borderRadius: '10px'
};
const DateRangePickerchart: React.FC<DateRangePickerchartProps> = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setsubscriptionreport,
  subscriptionreport
}) => {
  const dispatch = useDispatch();
  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const date = new Date(event.target.value);
    const date = moment(event.target.value).toDate();
    if (!isNaN(date.getTime())) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const date = new Date(event.target.value);
    const date = moment(event.target.value).toDate();
    if (!isNaN(date.getTime())) {
      setEndDate(date);
    }
  };
  const UpdateZoho = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/zoho/backdated/entries`);
      if (response.data.message) {
        dispatch(
          openSnackbar({
            open: true,
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Stack direction="column" spacing={2} pt={2} minWidth={150}>
        <TextField
          // type="datetime-local"
          type="date"
          label="Start Date"
          // value={startDate ? startDate.toISOString().slice(0, 16) : ''}
          value={moment(startDate).format().slice(0, 10)}
          onChange={handleStartDateChange}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          // type="datetime-local"
          type="date"
          label="End Date"
          // value={endDate ? endDate.toISOString().slice(0, 16) : ''}
          value={moment(endDate).format().slice(0, 10)}
          onChange={handleEndDateChange}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button sx={bgColor} onClick={() => UpdateZoho()}>
          {`Update Zoho Entry`}
        </Button>
        <Button sx={bgColor} onClick={() => setsubscriptionreport(!subscriptionreport)}>
          {`Show Subscription Report`}
        </Button>
      </Stack>
    </div>
  );
};

export default DateRangePickerchart;
