import React, { useState } from 'react';
import { Button, InputLabel, Stack, TextField } from '@mui/material';
const styles = { width: '40%', height: '30px' };
const buttonBackgroud =
  'linear-gradient(103.4deg, #2D00D2 12.04%, #2C00D3 30.87%, #3700C8 46.11%, #3E00C0 63.54%, #4B00B4 80.82%, #5400AA 93.76%)';
const Refund = ({ formik }: any) => {
  const { values } = formik;
  const [disable, setdisable] = useState(true)
  return (
    <div>
      {' '}
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button
          sx={{ color: 'white', background: `${buttonBackgroud}`, opacity:`${disable?'0.2':'1'}`, fontWeight: '700', width: '10%', height: '40px' }}
          // color="secondary
          disabled={disable}
          onClick={() => {
            formik.setFieldValue('refundamount', 0);
            setdisable(!disable);
          }}
        >
          Partial Refund
        </Button>
        <Button
          sx={{ color: 'white', background: `${buttonBackgroud}`,opacity:`${!disable?'0.2':'1'}`, fontWeight: '700', width: '15%', height: '40px' }}
          color="secondary"
          onClick={() => {
            formik.setFieldValue('refundamount', values.totalAmount);
            setdisable(!disable);
          }}
          disabled={!disable}
        >
          Full Amount Refund
        </Button>
        <Button
          sx={{ color: 'white', background: `${buttonBackgroud}`, opacity:`${values.normalrefund?'0.2':'1'}`, fontWeight: '700', width: '10%', height: '40px' }}
          // color="secondary
          disabled={values.normalrefund}
          onClick={() => {
            formik.setFieldValue('normalrefund', true);
            formik.setFieldValue('instantrefund', false);
          }}
        >
          Normal Refund 
        </Button>
        <Button
          sx={{ color: 'white', background: `${buttonBackgroud}`,opacity:`${values.instantrefund?'0.2':'1'}`, fontWeight: '700', width: '15%', height: '40px' }}
          color="secondary"
          onClick={() => {
            formik.setFieldValue('instantrefund', true);
            formik.setFieldValue('normalrefund', false);
          }}
          disabled={values.instantrefund}
        >
          Instant Refund
        </Button>
      </Stack>
      <Stack direction="column" gap={2} padding={2}>
        <Stack direction="row" justifyContent="space-between">
          <InputLabel>Refund amount :</InputLabel>
          <TextField type="number" name="refundamount" value={values.refundamount} onChange={formik.handleChange} sx={styles} />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <InputLabel>Reason for refund :</InputLabel>
          <TextField type="text" name="refundreason" value={values.refundreason} onChange={formik.handleChange} sx={styles} />
        </Stack>
      </Stack>
    </div>
  );
};

export default Refund;
