import React, { useEffect, useState } from 'react';
import { Button, InputLabel, Stack } from '@mui/material';
import { Box } from '@mui/material';
import axios from 'utils/axios';
import { BASE_URL } from 'config';
import moment from 'moment';
import BarChart from './BarChart';
import { columns } from './constant';
import BasicTable from '../../components/react-table/BasicTable';
import Loader from 'components/Loader';
import DailyActivitySubscription from './DailyActivitySubscription';
const bgColor = {
  background: 'linear-gradient(103.4deg, #2D00D2 12.04%, #2C00D3 30.87%, #3700C8 46.11%, #3E00C0 63.54%, #4B00B4 80.82%, #5400AA 93.76%)',
  color: ' #FFFFFF',
  borderRadius: '10px',
  maxWidth: '250px',
  paddingLeft: '10px'
};
const SubscriptionReport = ({ subscriptionreport, startDate, endDate }: any) => {
  const [data, setData] = useState<any>({});
  const [subscriptions, setsubscriptions] = useState({});
  const [tabledata, settabledata] = useState<any>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyactivity, setdailyactivity] = useState(false);
  const init = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/admin//subscriptionreport`);
      if (response.data) {
        setData(response.data || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setIsExporting(false)
    }
  };
  const handleFilter = async () => {
    const formattedStart = moment(startDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      .startOf('day')
      .toISOString();
    const formattedEnd = moment(endDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      .endOf('day')
      .toISOString();
    const Subscriptiondata = data?.filter((item: any) => {
      let itemDate = '';
      if (item?.startTime) {
        itemDate = moment(item?.startTime).toISOString();
      }
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const Cashsubscription = Subscriptiondata?.filter((item: any) => item.product === 'Cash Stocks Positional');
    const Stocksubscription = Subscriptiondata?.filter((item: any) => item.product === 'Stock Options');
    const Momentumsubscription = Subscriptiondata?.filter((item: any) => item.product === 'Momentum Futures');
    const Indexsubscription = Subscriptiondata?.filter((item: any) => item.product === 'Index Options');
    const Optionsdbsubscription = Subscriptiondata?.filter((item: any) => item.product === 'Options Combo');
    const Indexspreadubscription = Subscriptiondata?.filter((item: any) => item.product === 'Index Spreads');

    const achtiveCashsubscription = Cashsubscription?.filter((item: any) => item.active);
    const achtiveStocksubscription = Stocksubscription?.filter((item: any) => item.active);
    const achtiveMomentumsubscription = Momentumsubscription?.filter((item: any) => item.active);
    const achtiveIndexsubscription = Indexsubscription?.filter((item: any) => item.active);
    const achtiveOptionsdbsubscription = Optionsdbsubscription?.filter((item: any) => item.active);
    const achtiveIndexspreadubscription = Indexspreadubscription?.filter((item: any) => item.active);

    const expiredCashsubscription = Cashsubscription?.filter((item: any) => !item.active);
    const expiredStocksubscription = Stocksubscription?.filter((item: any) => !item.active);
    const expiredMomentumsubscription = Momentumsubscription?.filter((item: any) => !item.active);
    const expiredIndexsubscription = Indexsubscription?.filter((item: any) => !item.active);
    const expiredOptionsdbsubscription = Optionsdbsubscription?.filter((item: any) => !item.active);
    const expiredIndexspreadubscription = Indexspreadubscription?.filter((item: any) => !item.active);

    setsubscriptions(() => {
      return {
        labels: [
          `Cash Stocks Positional(${Cashsubscription.length})`,
          `Stock Options(${Stocksubscription.length})`,
          `Momentum Futures(${Momentumsubscription.length})`,
          `Index Options(${Indexsubscription.length})`,
          `Options Combo(${Optionsdbsubscription.length})`,
          `Index Spreads(${Indexspreadubscription.length})`
        ],
        datasets: [
          {
            label: 'Active',
            data: [
              achtiveCashsubscription.length,
              achtiveStocksubscription.length,
              achtiveMomentumsubscription.length,
              achtiveIndexsubscription.length,
              achtiveOptionsdbsubscription.length,
              achtiveIndexspreadubscription.length
            ],
            backgroundColor: ['rgb(144, 238, 145)'],
            borderColor: ['rgb(144, 238, 145)'],
            stack: 'bar',
            borderRadius: 5
          },
          {
            label: 'InActive',
            data: [
              -expiredCashsubscription.length,
              -expiredStocksubscription.length,
              -expiredMomentumsubscription.length,
              -expiredIndexsubscription.length,
              -expiredOptionsdbsubscription.length,
              -expiredIndexspreadubscription.length
            ],
            backgroundColor: ['rgba(255,99,132)'],
            borderColor: ['rgba(54,162,235,1)'],
            stack: 'bar',
            borderRadius: 5
          }
        ]
      };
    });
    settabledata(() => {
      return [
        {
          dateRange: `${formattedStart.split('T')[0]} / ${formattedEnd.split('T')[0]}`,
          product: 'Cash Stocks Positional',
          totaluser: Cashsubscription.length,
          active: achtiveCashsubscription.length,
          inactive: expiredCashsubscription.length
        },
        {
          dateRange: `${formattedStart.split('T')[0]} / ${formattedEnd.split('T')[0]}`,
          product: 'Stock Options',
          totaluser: Stocksubscription.length,
          active: achtiveStocksubscription.length,
          inactive: expiredStocksubscription.length
        },
        {
          dateRange: `${formattedStart.split('T')[0]} / ${formattedEnd.split('T')[0]}`,
          product: 'Momentum Futures',
          totaluser: Momentumsubscription.length,
          active: achtiveMomentumsubscription.length,
          inactive: expiredMomentumsubscription.length
        },
        {
          dateRange: `${formattedStart.split('T')[0]} / ${formattedEnd.split('T')[0]}`,
          product: 'Index Options',
          totaluser: Indexsubscription.length,
          active: achtiveIndexsubscription.length,
          inactive: expiredIndexsubscription.length
        },
        {
          dateRange: `${formattedStart.split('T')[0]} / ${formattedEnd.split('T')[0]}`,
          product: 'Options Combo',
          totaluser: Optionsdbsubscription.length,
          active: achtiveOptionsdbsubscription.length,
          inactive: expiredOptionsdbsubscription.length
        },
        {
          dateRange: `${formattedStart.split('T')[0]} / ${formattedEnd.split('T')[0]}`,
          product: 'Index Spreads',
          totaluser: Indexspreadubscription.length,
          active: achtiveIndexspreadubscription.length,
          inactive: expiredIndexspreadubscription.length
        }
      ];
    });
  };
  //   console.log(orderentrydata)
  useEffect(() => {
    if (subscriptionreport) {
      init();
    }
  }, []);
  useEffect(() => {
    if (data.length > 1) {
      handleFilter();
    }
  }, [data, startDate, endDate, dailyactivity]);
  const handleExportClick = () => {
    setIsExporting(true);
    function convertJsonToCsv(jsonData: any) {
      // Get headers from the first object in the array
      const headers = Object.keys(jsonData[0]);

      // Create a CSV string with headers as the first row
      let csvString = headers.join(',') + '\n';

      // Loop through the array and create a new row for each object
      for (const obj of jsonData) {
        const values = [];

        // Loop through the keys in the object and add them to the row
        for (const key of headers) {
          values.push(obj[key]);
        }

        // Add the row to the CSV string
        csvString += values.join(',') + '\n';
      }

      return csvString;
    }

    function downloadCsvFile(csvString: any, filename: any) {
      const link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExporting(false)
    }
    const csvString = convertJsonToCsv(tabledata);
    downloadCsvFile(csvString, 'data.csv');
  };
  return (
    <>
      {' '}
      {(!tabledata || loading) && <Loader />}
      <Box width={'100%'} height={'100%'} p={2}>
        <Stack direction={'row'} gap={2}>
          <Button sx={bgColor} onClick={() => setdailyactivity(!dailyactivity)}>
            {`Show Subscription Daily Activity`}
          </Button>
        </Stack>
        <Stack direction={'column'} justifyContent={'space-between'} p={2} gap={2}>
          {dailyactivity ? (
            <>
              <DailyActivitySubscription dailyactivity={dailyactivity} startDate={startDate} endDate={endDate} />
            </>
          ) : (
            <>
              <Stack
                width={'100%'}
                height={'45vh'}
                borderRadius={5}
                bgcolor={'white'}
                boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
                alignItems={'center'}
              >
                <Stack width={'80%'} height={'45vh'} pr={7} pl={7}>
                  {Object.keys(subscriptions).length > 0 && (
                    <BarChart barChartData={subscriptions} startDate={startDate} endDate={endDate} title={'Subscription Date'} />
                  )}
                </Stack>
              </Stack>
              <Stack
                width={'100%'}
                height={'45vh'}
                borderRadius={5}
                bgcolor={'white'}
                boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
                alignItems={'center'}
                p={5}
              >
                <Stack direction="row" spacing={2} sx={{ position: 'absolute', right: 100 }}>
                  <Button sx={bgColor} onClick={handleExportClick} disabled={isExporting}>
                    {isExporting ? 'Exporting...' : 'Export CSV'}
                  </Button>
                </Stack>
                {(!tabledata || loading) && <Loader />}
                <BasicTable columns={columns} data={tabledata} striped />
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default SubscriptionReport;
