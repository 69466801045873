import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { InputLabel, Stack } from '@mui/material';
import SelectField from 'components/SelectField';
import { BASE_URL } from 'config';
import BarChart from './BarChart';
import { Box } from '@mui/material';
import { dates } from './constant';
import DateRangePickerchart from './DateRangePicker';
import moment from 'moment';
import Loader from 'components/Loader';
import SubscriptionReport from './SubscriptionReport';
const SalesDashboard = () => {
  const [data, setData] = useState<any>({});
  const [orderentrydata, setorderentrydata] = useState({});
  const [revenudata, setrevenudata] = useState({});
  const [productdata, setproductdata] = useState({});
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState('today'); // Default range
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionreport, setsubscriptionreport] = useState(false);
  const init = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/admin/sales/dashboard`);
      if (response.data) {
        setData(response.data || []);
        handleFilter();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  //   console.log(orderentrydata)
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (Object.keys(data).length > 1) {
      handleFilter();
    }
  }, [startDate, endDate, data]);
  const handleDateRangeChange = (event: any) => {
    const selectedRange = dates.find((d) => d.value === event.target.value);
    if (selectedRange) {
      const [start, end] = selectedRange.range();
      setStartDate(start.toDate());
      setEndDate(end.toDate());
      setDateRange(event.target.value);
    }
  };
  const handleFilter = () => {
    const formattedStart = moment(startDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      .startOf('day')
      .toISOString();
    const formattedEnd = moment(endDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      .endOf('day')
      .toISOString();
    const zohoinvoiceorder = data?.zohoinvoiceorder.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const dbordersentry = data?.dbordersentry.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const razorpayorder = data?.razorpayorder.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const paidzohoorder = data?.paidzohoorder.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const paiddbordersentry = data?.paiddbordersentry.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const paidrazorpayorder = data?.paidrazorpayorder.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const refundzohoorder = data?.refundzohoorder.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const retunddbordersentry = data?.retunddbordersentry.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });
    const refundrazorpayorder = data?.refundrazorpayorder.filter((item: any) => {
      const itemDate = moment(item.created_at).toISOString();
      return itemDate >= formattedStart && itemDate <= formattedEnd;
    });

    setorderentrydata(() => {
      return {
        labels: [`Zoho(${zohoinvoiceorder?.length})`, `Admin Entry(${dbordersentry?.length})`, `Razorpay(${razorpayorder?.length})`],
        datasets: [
          {
            label: 'Paid',
            data: [paidzohoorder.length, paiddbordersentry.length, paidrazorpayorder.length],
            backgroundColor: ['rgb(144, 238, 145)'],
            borderColor: ['rgb(144, 238, 145)'],
            stack: 'bar',
            borderRadius: 5
          },
          {
            label: 'refunded',
            data: [refundzohoorder.length, retunddbordersentry.length, refundrazorpayorder.length],
            backgroundColor: ['rgba(255,99,132)'],
            borderColor: ['rgba(54,162,235,1)'],
            stack: 'bar',
            borderRadius: 5
          }
        ]
      };
    });

    const zohoinvoiceorderRevenue = zohoinvoiceorder.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const dbordersentryRevenue = dbordersentry.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const razorpayorderRevenue = razorpayorder.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const paidzohoorderRevenue = paidzohoorder.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const paiddbordersentryRevenue = paiddbordersentry.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const paidrazorpayorderRevenue = paidrazorpayorder.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const refundzohoorderRevenue = refundzohoorder.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const retunddbordersentryRevenue = retunddbordersentry.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    const refundrazorpayorderRevenue = refundrazorpayorder.reduce((acc: any, curr: any) => {
      return acc + curr.amount / 100;
    }, 0);
    setrevenudata(() => {
      return {
        labels: [
          `Zoho(${zohoinvoiceorderRevenue.toFixed(2)})`,
          `Admin Entry(${dbordersentryRevenue.toFixed(2)})`,
          `Razorpay(${razorpayorderRevenue.toFixed(2)})`
        ],
        datasets: [
          {
            label: 'Paid',
            data: [paidzohoorderRevenue, paiddbordersentryRevenue, paidrazorpayorderRevenue],
            backgroundColor: ['rgb(144, 238, 145)'],
            borderColor: ['rgb(144, 238, 145)'],
            stack: 'bar',
            borderRadius: 5
          },
          {
            label: 'refunded',
            data: [refundzohoorderRevenue, retunddbordersentryRevenue, refundrazorpayorderRevenue],
            backgroundColor: ['rgba(255,99,132)'],
            borderColor: ['rgba(54,162,235,1)'],
            stack: 'bar',
            borderRadius: 5
          }
        ]
      };
    });
    const Cashorder = dbordersentry.filter((item: any) => item.product === 'Cash Stocks Positional');
    const Stockorders = dbordersentry.filter((item: any) => item.product === 'Stock Options');
    const Momentumorder = dbordersentry.filter((item: any) => item.product === 'Momentum Futures');
    const Indexorder = dbordersentry.filter((item: any) => item.product === 'Index Options');
    const Optionsdborders = dbordersentry.filter((item: any) => item.product === 'Options Combo');
    const Indexorders = dbordersentry.filter((item: any) => item.product === 'Index Spreads');

    const Cashpaidorder = paiddbordersentry.filter((item: any) => item.product === 'Cash Stocks Positional');
    const Stockpaidorders = paiddbordersentry.filter((item: any) => item.product === 'Stock Options');
    const Momentumpaidorder = paiddbordersentry.filter((item: any) => item.product === 'Momentum Futures');
    const Indexpaidorder = paiddbordersentry.filter((item: any) => item.product === 'Index Options');
    const Optionsdbpaidorders = paiddbordersentry.filter((item: any) => item.product === 'Options Combo');
    const Indexpaidorders = paiddbordersentry.filter((item: any) => item.product === 'Index Spreads');

    const Cashrefundorder = retunddbordersentry.filter((item: any) => item.product === 'Cash Stocks Positional');
    const Stockrefundorders = retunddbordersentry.filter((item: any) => item.product === 'Stock Options');
    const Momentumrefundorder = retunddbordersentry.filter((item: any) => item.product === 'Momentum Futures');
    const Indexrefundorder = retunddbordersentry.filter((item: any) => item.product === 'Index Options');
    const Optionsdbrefundorders = retunddbordersentry.filter((item: any) => item.product === 'Options Combo');
    const Indexrefundorders = retunddbordersentry.filter((item: any) => item.product === 'Index Spreads');
    setproductdata(() => {
      return {
        labels: [
          `Cash Stocks Positional(${Cashorder.length})`,
          `Stock Options(${Stockorders.length})`,
          `Momentum Futures(${Momentumorder.length})`,
          `Index Options(${Indexorder.length})`,
          `Options Combo(${Optionsdborders.length})`,
          `Index Spreads(${Indexorders.length})`
        ],
        datasets: [
          {
            label: 'Paid',
            data: [
              Cashpaidorder.length,
              Stockpaidorders.length,
              Momentumpaidorder.length,
              Indexpaidorder.length,
              Optionsdbpaidorders.length,
              Indexpaidorders.length
            ],
            backgroundColor: ['rgb(144, 238, 145)'],
            borderColor: ['rgb(144, 238, 145)'],
            stack: 'bar',
            borderRadius: 5
          },
          {
            label: 'refunded',
            data: [
              Cashrefundorder.length,
              Stockrefundorders.length,
              Momentumrefundorder.length,
              Indexrefundorder.length,
              Optionsdbrefundorders.length,
              Indexrefundorders.length
            ],
            backgroundColor: ['rgba(255,99,132)'],
            borderColor: ['rgba(54,162,235,1)'],
            stack: 'bar',
            borderRadius: 5
          }
        ]
      };
    });
  };
  return (
    <div>
      {(!data?.zohoinvoiceorder || loading) && <Loader />}
      <Box width={'100%'} height={'100%'} >
        <Stack direction={'row'} justifyContent={'space-between'} p={2} gap={2}>
          <Stack spacing={1} width={150}>
            <SelectField options={dates} value={dateRange} onChange={handleDateRangeChange} name="advisoryId" />
            <DateRangePickerchart
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setsubscriptionreport={setsubscriptionreport}
              subscriptionreport={subscriptionreport}
            />
          </Stack>
          {subscriptionreport ? (
            <>
              <SubscriptionReport subscriptionreport={subscriptionreport} startDate={startDate} endDate={endDate}/>
            </>
          ) : (
            <>
              <Stack
                width={'100%'}
                height={'45vh'}
                borderRadius={5}
                bgcolor={'white'}
                boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
                alignItems={'center'}
              >
                <Stack width={'80%'} height={'42vh'} pr={7} pl={7}>
                  {Object.keys(orderentrydata).length > 0 && (
                    <BarChart barChartData={orderentrydata} startDate={startDate} endDate={endDate} title={'Orders'} />
                  )}
                </Stack>
              </Stack>
              <Stack
                width={'100%'}
                height={'45vh'}
                borderRadius={5}
                bgcolor={'white'}
                boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
                alignItems={'center'}
              >
                <Stack width={'80%'} height={'42vh'} pr={7} pl={7}>
                  {Object.keys(revenudata).length > 0 && (
                    <BarChart barChartData={revenudata} startDate={startDate} endDate={endDate} title={'Revenue'} />
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
        {!subscriptionreport && (
          <Stack direction={'row'} justifyContent={'space-between'} pr={2} pl={2} pb={2} gap={2}>
            <Stack width={'100%'} height={'45vh'} borderRadius={5} bgcolor={'white'} boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}>
              <Stack width={'80%'} height={'42vh'} pr={7} pl={7}>
                {Object.keys(productdata).length > 0 && (
                  <BarChart barChartData={productdata} startDate={startDate} endDate={endDate} title={'Product vise'} />
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Box>
    </div>
  );
};

export default SalesDashboard;
