import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as Chartjs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults
} from 'chart.js';
import moment from 'moment';

Chartjs.register({
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
});
defaults.maintainAspectRatio = false;
defaults.responsive = true;

const BarChart = ({ barChartData, startDate, endDate, title, charttype }: any) => {
  const lineoption: any = {
    fill: false,
    interaction: {
      intersect: false
    },
    radius: 0
  };

  const option: any = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${title} Entry`,
        padding: {
          top: 10,
          bottom: 30
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: `${startDate ? moment(startDate).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')} - ${
            endDate ? moment(endDate).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')
          }`
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: title
        }
        // min: 0,
        // max: 1000
      }
    }
  };
  return (
    <>{charttype === 'line' ? <Line options={lineoption} data={barChartData}></Line> : <Bar options={option} data={barChartData}></Bar>}</>
  );
};

export default BarChart;
