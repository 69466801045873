import React from 'react';
import { TextFieldProps, Grid, TextField } from '@mui/material';
import { InputLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';


const DateTimeComponent = ({ formik }: any) => {
  const { values } = formik;
  const FastinputfieldStyle = {
    bgcolor: "#ECEFFF", borderRadius: "10px", padding: '8px 10px', width: 335, border: "none",
    '& .MuiOutlinedInput-notchedOutline ': {
      border: 'none',
      boxShadow: "none"
    },
    "& .focus": { boxShadow: "none" },
  }

  return (
    <Grid container direction="row" >
      <InputLabel sx={{ mt: 1, width: "150px", padding: '10px', fontSize: '15px', fontWeight: '700' }}> Date and Time </InputLabel>
      <Grid item >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={values?.created_at || "-"}
            onChange={(d) => {
              let date = moment(d).format("YYYY-MM-DDTHH:MM:SSZ")            
              formik.setFieldValue(`created_at`, date)
            }}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                sx={FastinputfieldStyle}
                {...params}
                id={'createdAt'}
                name={'created_at'}
              />
            )}

          />

        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default DateTimeComponent;
