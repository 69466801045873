import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import config from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  let path = config.defaultPath;
  useEffect(() => {
    if (isLoggedIn) {
      if (localStorage.getItem('permission')) {
        let permission = JSON.parse(localStorage.getItem('permission') || '');

        if (permission.length === 0) {
          path = './';
        }
        if (permission.map((x: any) => x.label).includes('Research')) {
          path = permission.length > 5 ? './risk-profile' : './advice';
        }
        if (permission.map((x: any) => x.label).includes('Advisory Admin')) {
          path = permission.length > 5 ? './risk-profile' : './advisory-master';
        }
        if (permission.map((x: any) => x.label).includes('Learn Module Admin')) {
          path = permission.length > 5 ? './risk-profile' : './special-programs';
        }
        if (permission.map((x: any) => x.label).includes('Accounts')) {
          path = permission.length > 5 ? './risk-profile' : './orders';
        }
        if (permission.map((x: any) => x.label).includes('Marketing')) {
          path = permission.length > 5 ? './risk-profile' : './add-coupons';
        }

        if (permission.map((x: any) => x.label).includes('Support(User-action)')) {
          path = permission.length > 5 ? './risk-profile' : './user/action';
        }
      }
      navigate(path, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default GuestGuard;
